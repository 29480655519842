import {useTranslation} from "react-i18next"; 
const Faq = () =>{
    const { t } = useTranslation();
    console.log('test');
    return (

        <section className='container px-4 mx-auto py-10 mb-10' data-aos="fade-up-left" data-aos-duration="1500" data-aos-delay="900">
        {/* Faq title Start */}
        <div className="text-4xl font-semibold text-primary flex justify-center mb-10">
            <div>
                <h2 className="">{t('frequently_ask_questions')}</h2>
                <div className="border border-t-2 border-t-primary w-16 mt-3"></div>
            </div>
        </div>
        {/* Faq title end  */}

        <div className='p-8 border border-primary rounded-lg'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>
                <div> 
                    {/* Faq item start  */}
                    <div tabIndex="0" className="my-3 collapse collapse-arrow border border-base-200 rounded-lg">
                        <div className="collapse-title text-xl font-medium">
                          {t('what_is_rx_courier')}
                        </div>
                        <div className="collapse-content"> 
                            <p>
                               {t('what_is_rx_courier_content')}
                            </p>
                        </div>
                    </div>
                    <div tabIndex="1" className="my-3 collapse collapse-arrow border border-base-200 rounded-lg">
                        <div className="collapse-title text-xl font-medium">
                            {t("where_you_can_deliver_product")}
                        </div>
                        <div className="collapse-content"> 
                            <p>{t('where_you_can_deliver_product_content')}</p>
                         </div>
                    </div>
                    {/* Faq item end  */}
               

                   {/* Faq item start  */}
                   <div tabIndex="2" className="my-3 collapse collapse-arrow border border-base-200 rounded-lg">
                            <div className="collapse-title text-xl font-medium">
                            {t('what_kind_of_services_does_rx_courier_provide')}
                            </div>
                            <div className="collapse-content"> 
                                <p>{t('what_kind_of_services_does_rx_courier_provide_content')}
                                </p>
                                <ul className="list-disc py-10 ml-10">
                                    <li>{t("e_commerce_delivery")}</li>
                                    <li>{t("pick_Drop")}</li>
                                    <li>{t("packaging")}</li>
                                    <li>{t("warehousing")}</li>
                                </ul>
                            </div>
                        </div>
                        {/* Faq item end  */}
                        
                        {/* Faq item start  */}
                        <div tabIndex="3" className="my-3 collapse collapse-arrow border border-base-200 rounded-lg">
                            <div className="collapse-title text-xl font-medium">
                                {t('how_can_a_merchant_track_their_parcel_delivery')}
                            </div>
                            <div className="collapse-content"> 
                                <p>
                                    {t('how_can_a_merchant_track_their_parcel_delivery_content')}
                                </p>
                            </div>
                        </div>
                        {/* Faq item end  */}

                </div>
                <div>
                      
                        
                        {/* Faq item start  */}
                        <div tabIndex="4" className="my-3 collapse collapse-arrow border border-base-200 rounded-lg">
                            <div className="collapse-title text-xl font-medium">
                               {t("want_to_know_about_your_delivery_charges")}
                            </div>
                            <div className="collapse-content"> 
                                <p>{t("want_to_know_about_your_delivery_charges_content")}</p>
                            </div>
                        </div>
                        {/* Faq item end  */}
                        
                        {/* Faq item start  */}
                        <div tabIndex="5" className="my-3 collapse collapse-arrow border border-base-200 rounded-lg">
                            <div className="collapse-title text-xl font-medium">
                                {t('how_to_contact_rx_courier')}
                            </div>
                            <div className="collapse-content text-center"> 
                                <p>{t('how_to_contact_rx_courier_content')}</p>
                                    Phone : +59177331160  
                                    <br></br>
                                    Email : elviolopez@shalomservice.com     
                            </div>
                        </div>
                        {/* Faq item end  */}
                        
                        {/* Faq item start  */}
                        <div tabIndex="6" className="my-3 collapse collapse-arrow border border-base-200 rounded-lg">
                            <div className="collapse-title text-xl font-medium">
                                {t('i_don_have_any_pagebook_page_or_email_how_can_create_merchant_account')}
                            </div>
                            <div className="collapse-content"> 
                                <div className="py-5">

                                <p>{t('i_don_have_any_pagebook_page_or_email_how_can_create_merchant_account_content')} </p>
                                <a href={`${process.env.REACT_APP_BASE_URL}/register`} className="mb-5"><u>Click</u></a>
                                </div>
                            </div>
                        </div>
                        {/* Faq item end  */}
                        
                        {/* Faq item start  */}
                        <div tabIndex="7" className="my-3 collapse collapse-arrow border border-base-200 rounded-lg">
                            <div className="collapse-title text-xl font-medium">
                                {t('what_kind_of_products_does_rx_courier_deliver')}
                            </div>
                            <div className="collapse-content"> 
                                <p> {t('what_kind_of_products_does_rx_courier_deliver_content')}</p>
                            </div>
                        </div>
                        {/* Faq item end  */}
                        
                </div>
            </div>
             
        </div> 
    </section>

    );
}

export default Faq;
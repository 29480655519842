import React, { useEffect, useState } from "react"; 
import { Link as ScrollLink } from "react-scroll";

// import useTranslation from 'next-translate/useTranslation';
 
import { Link, NavLink, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next"; 

const Header = () => {
 
  const location = useLocation(); 
  const { t, i18n } = useTranslation();

  const [navScroll, setNavScroll] = useState(false);
  const pathName = location.pathname;
  // Nav backgoround change on scroll
  const setNavBg = () => {
    if (window.scrollY >= 100) {
      setNavScroll(true);
    } else {
      setNavScroll(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", setNavBg);
  }, []);

 
   return (
    <header
      className={`fixed top-0 w-full border-b border-b-[#ffffff44] z-20 font-semibold ${
      "bg-base-100 shadow-xl"
      } ${pathName !== "/" ? "bg-base-100 shadow-xl" : ""}`}
    >
     
      <div className="container mx-auto">
        <div
          className={`navbar text-white w-full  `}
        >
          {/* Mobile Navbar  */}
          <div className="navbar-start w-full md:w-96 flex justify-between md:justify-start px-2 md:px-0">
            <div className="dropdown">
              <label
                tabIndex="0"
                className={`btn bg-transparent menu-toggle mr-2 md:hidden ${
                    "border-base-100 text-dark"
                }`}
                style={{ color:'black' }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h8m-8 6h16"
                  />
                </svg>
              </label>
              <ul
                tabIndex="0"
                className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-white  rounded-box"
              >
              
                <li className="ml-3">
                  <ScrollLink
                    to="services"
                    activeClass="active-scroll"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-100}
                  >
                    {t('services')}
                  </ScrollLink>
                </li>
                
                <li className="ml-3">
                  <ScrollLink
                    to="pricing"
                    activeClass="active-scroll"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-100}
                  >
                    {t('pricing')}
                    
                  </ScrollLink>
                </li>
                <li className="ml-3">
                  <ScrollLink
                    to="contact-us"
                    activeClass="active-scroll"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-140}
                  >
                    {t('contact_us')} 
                  </ScrollLink>
                </li>

                <li className="ml-3">
                  <a href={`${process.env.REACT_APP_BASE_URL}/login`} target="_blank"  >
                      <label
                     
                        className={`login-btn cursor-pointer border-none font-bold  ${pathName !== "/" ? "bg-primary text-white" : ""}`}
                      >
                        {t('merchant')} {t('login')}
                      </label>
                    </a>
                </li>
              </ul>
            </div>
            <div className="locale">
                      {i18n.language === 'en'?
                        <a  href="#" onClick={()=>i18n.changeLanguage('es')} ><span style={{ textTransform:'uppercase' }}>es</span></a>:
                        <a  href="#" onClick={()=>i18n.changeLanguage('en')} ><span style={{ textTransform:'uppercase' }}>en</span></a> 
                      }
                     
            </div>
            <Link to="/" className="normal-case text-3xl"  >
                  <img   src='/rx-logo.png' alt="logo" style={{ height:"70px" }} />
            </Link>
          </div>

          {/* Regular Navbar  */}
          <div className="navbar-end hidden md:flex w-full">
            <ul className="menu menu-horizontal p-0 w-full flex justify-end">
               
              <li className="ml-3">
                <ScrollLink
                  to="services"
                  activeClass="active-scroll"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-100}
                >
                {t('services')}
                </ScrollLink>
              </li>
             
              <li className="ml-3">
                <ScrollLink
                  to="pricing"
                  activeClass="active-scroll"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-100}
                >
                   {t('pricing')}
                </ScrollLink>
              </li>
              <li className="ml-3">
                <ScrollLink
                  to="contact-us"
                  activeClass="active-scroll"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-140}
                >
                 {t('contact_us')} 
                </ScrollLink>
              </li>
              <li className="ml-3">
                 
                <a href={`${process.env.REACT_APP_BASE_URL}/login`} target="_blank" className="login-a p-0">
                  <label
                    className={`login-btn btn border-none font-bold hover:text-white ${
                        "bg-primary text-white" }`}
                    style={pathName !== "/" ? { color: "white" } : {}}
                  >
                      {t('merchant')} {t('login')}
                  </label>
                </a>
                  
              </li>
              <li className="ml-3 regular">
                  {i18n.language === 'en'?
                     <a href="#" onClick={()=>i18n.changeLanguage('es')} ><span style={{ textTransform:'uppercase' }}>es</span></a> :
                     <a href="#" onClick={()=>i18n.changeLanguage('en')} ><span style={{ textTransform:'uppercase' }}>en</span></a> 
                  }
                     
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
